import axios from "axios";

const API = axios.create({
  baseURL: "https://api-project-sims.herokuapp.com/scrape",
  //baseURL: "http://localhost:5000/scrape",
});

export const getRating = (url) => {
  return API.get("/?url=" + url);
};
