import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  entirePage: {
    backgroundColor: theme.palette.background.paper,
  },

  heroContainer: {
    padding: theme.spacing(8, 0, 6),
    padding: "150px 0 0 0",

    [theme.breakpoints.down("sm")]: {
      padding: "30px 0 0 0",
    },
  },

  icon: {
    marginRight: "2px",
    color: "#21214d",
  },

  buttons: {
    marginTop: "40px",
  },

  cardGrid: {
    padding: "20px 0",
  },

  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  cardGridItem: {
    padding: "10px",
  },

  cardMedia: {
    paddingTop: "56.25%",
  },

  cardContent: {
    flexGrow: 1,
  },

  footer: {
    padding: "50px 0",
    margin: "300px 0 0 0",
  },

  urlInput: {
    margin: "40px 0 0 0",
    width: "80%",
  },

  resultsContainer: {
    marginTop: "30px",
  },

  resultsCard: {
    padding: "20px",
    width: "fit-content",
  },

  resultColorRed: {
    color: theme.palette.error.main,
  },

  resultColorBlue: {
    color: theme.palette.info.dark,
  },

  resultColorGreen: {
    color: theme.palette.success.main,
  },

  websiteLogo: {
    borderRadius: "5px",
  },

  warning: {
    margin: "10px 0 0 5px",
    color: theme.palette.error.main,
  },

  appBar: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export default useStyles;
