import React, { useState, useEffect } from "react";
import {
  Typography,
  AppBar,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CssBaseline,
  Grid,
  Toolbar,
  Container,
  TextField,
  Button,
  CircularProgress,
  IconButton,
  Box,
} from "@material-ui/core";
import { Menu, RemoveRedEyeOutlined } from "@mui/icons-material";

import { getRating } from "./api/index";

import useStyles from "./styles";

const currentYear = new Date().getFullYear();

const App = () => {
  const classes = useStyles();
  const [urlInput, setUrlInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [ratingResults, setRatingResults] = useState({
    bias: "",
    details: { repCount: null, demCount: null },
  });
  const [imageUrl, setImageUrl] = useState("");
  const [warning, setWarning] = useState("");

  const handleURLInput = (e) => {
    setUrlInput(e.target.value);
    console.log(urlInput);
  };

  const fixUrl = (url) => {
    console.log(url);

    if (url.includes("https://") || url.includes("http://")) {
      return url;
    } else {
      return "https://" + url;
    }
  };

  const fixImageUrl = (url) => {
    console.log("Should be same: " + url);
    const index = url.indexOf(".com");

    const httpIndex = url.indexOf("//");
    const fixedImageUrl = url.substring(httpIndex + 2, index + 4);

    console.log("Fixed image url: " + fixedImageUrl);
    console.log("//logo.clearbit.com/" + fixedImageUrl);
    setImageUrl("//logo.clearbit.com/" + fixedImageUrl);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setWarning("");

    if (
      urlInput.length > 0 &&
      (urlInput.indexOf(".com") > 0 ||
        urlInput.indexOf(".net") > 0 ||
        urlInput.indexOf(".org") > 0)
    ) {
      setLoading(true);

      const fixedUrl = fixUrl(urlInput);
      fixImageUrl(fixedUrl);

      try {
        const result = await getRating(fixedUrl);
        console.log(result.data);
        setRatingResults(result.data);
      } catch (error) {
        setWarning("Web page not found - please try again");
      }

      setLoading(false);
    }
  };

  return (
    <div className={classes.entirePge}>
      <CssBaseline />
      <AppBar className={classes.appBar} elevation={0} position="static">
        <Toolbar>
          <RemoveRedEyeOutlined className={classes.icon} />
          <Typography
            component="div"
            sx={{ flexGrow: 1 }}
            color="textPrimary"
            variant="h6"
          >
            Cerno
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        <div className={classes.heroContainer}>
          <Container maxWidth="md">
            <Typography
              variant="h2"
              align="left"
              color="textPrimary"
              gutterBottom
            >
              Sift through the bias
            </Typography>
            <Typography
              variant="h5"
              align="left"
              color="textSecondary"
              paragraph
            >
              Uncover the political leanings of any article or web page
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                className={classes.urlInput}
                id="outlined-basic"
                label="Input URL here"
                variant="outlined"
                size="medium"
                margin="normal"
                onChange={handleURLInput}
              />
              <Typography className={classes.warning} variant="body1">
                {warning}
              </Typography>

              <div className={classes.buttons}>
                <Grid container spacing={2} justify="left">
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      color="primary"
                    >
                      Investigate
                    </Button>
                  </Grid>
                  {/*
                  <Grid item>
                    <Button variant="outlined" color="primary">
                      Secondary action
                    </Button>
                  </Grid>*/}
                  <Grid item>{loading && <CircularProgress />}</Grid>
                </Grid>
              </div>
            </form>

            {ratingResults?.bias.length > 0 && !loading && (
              <div className={classes.resultsContainer}>
                <Card
                  variant="raised"
                  raised={true}
                  className={classes.resultsCard}
                >
                  <Grid container spacing={4}>
                    <Grid item>
                      <Typography color="textSecondary" gutterBottom>
                        This web page leans:
                      </Typography>
                      <Typography
                        variant="h5"
                        gutterBottom
                        className={
                          ratingResults?.bias == "Conservative"
                            ? classes.resultColorRed
                            : ratingResults?.bias == "Liberal"
                            ? classes.resultColorBlue
                            : classes.resultColorGreen
                        }
                      >
                        {ratingResults?.bias}
                      </Typography>
                      <Typography color="textSecondary" variant="body2">
                        Conservative rating: {ratingResults?.details.repCount}
                      </Typography>
                      <Typography color="textSecondary" variant="body2">
                        Liberal rating: {ratingResults?.details.demCount}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img className={classes.websiteLogo} src={imageUrl}></img>
                    </Grid>
                  </Grid>
                </Card>
              </div>
            )}
          </Container>
        </div>

        {/*
        <Container className={classes.cardGrid} maxWidth="md">
          <Grid container spacing={0}>
            {cards.map((card) => {
              return (
                <Grid
                  className={classes.cardGridItem}
                  item
                  key={card}
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <Card className={classes.card}>
                    <CardMedia
                      className={classes.cardMedia}
                      image="https://source.unsplash.com/random"
                      title="Image title"
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography variant="h5" gutterBottom>
                        Card #{card}
                      </Typography>
                      <Typography>
                        This is a media card. you can use this section to
                        describe the content.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" color="primary">
                        View
                      </Button>
                      <Button size="small" color="primary">
                        Edit
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Container>
        */}
      </main>
      <footer className={classes.footer}>
        <Typography variant="subtitle2" align="center" gutterBottom>
          Copyright © {currentYear} Sims Pettway. All rights reserved.
        </Typography>
        <Typography variant="body2" align="center" color="textSecondary">
          Crafted with *magic* in ATL
        </Typography>
      </footer>
    </div>
  );
};

export default App;
